const InstallmentsRules = () => {
  return (
    <>
      <p className="text-left text-sm mt-4">
        <strong>Regras do Parcelamento:</strong>
      </p>
      <p className="text-left text-sm mt-4">
        O parcelamento para o cartão de crédito tem o valor mínimo de R$49,99
        por parcela em até 6x.
      </p>
      <p className="text-left text-sm mt-4">
        Para parcelamento de 7 à 10x a parcela mínima é de R$99,99.
      </p>
      <p className="text-sm mt-4">
        <strong>Confirmação de Informações:</strong>
      </p>
      <p className="text-sm mt-2">
        Para a <strong>sua segurança</strong>, a Decathlon possui uma área de
        segurança que poderá ser acionado em determinadas compras. Essa empresa
        poderá entrar em contato por telefone, em um dos números de contato do
        cliente, para verificação dos dados utilizados na hora da compra. Esta
        verificação pode ocorrer de segunda a sexta, das 8:00 até 19:00, e de
        sábado e domingo, de 08h00 até 17h00, em até 48 horas após o pagamento
        do seu pedido e tem o intuito exclusivo de confirmar que o próprio
        cliente realizou a compra.
      </p>
      <p className="text-sm mt-2">
        <strong>Atenção:</strong>Ressaltamos que dados como senha, código de
        segurança do cartão e o número completo do mesmo nunca serão
        solicitados.
      </p>
      <p className="text-sm mt-2">
        <strong>Atenção:</strong> A não validação dessas informações ocasionar o
        cancelamento do seu pedido.
      </p>
    </>
  )
}

export default InstallmentsRules
