const PaymentConditions = () => {
  return (
    <>
      <p className="text-left text-xs mt-4">
        <strong className="font-bold text-sm">
          Condições de Parcelamento:
        </strong>
      </p>
      <ul className="form-payment_installments text-left pl-10">
        <li className="text-sm">10x nas compras a partir de R$ 999,99</li>
        <li className="text-sm">9x nas compras a partir de R$ 899,99</li>
        <li className="text-sm">8x nas compras a partir de R$ 799,99</li>
        <li className="text-sm">7x nas compras a partir de R$ 699,99</li>
        <li className="text-sm">6x nas compras a partir de R$ 299,99</li>
        <li className="text-sm">5x nas compras a partir de R$ 249,99</li>
        <li className="text-sm">4x nas compras a partir de R$ 199,99</li>
        <li className="text-sm">2x nas compras a partir de R$ 99,99</li>
      </ul>
    </>
  )
}

export default PaymentConditions
