import { GatsbySeo } from 'gatsby-plugin-next-seo'
import FormaPagamento from 'src/components/servicos/ServicesPage/FormaPagamento'

function Page() {
  return (
    <>
      <GatsbySeo title="Formas de Pagamento | Decathlon" />
      <FormaPagamento />
    </>
  )
}

export default Page
