const PaymentOptions = () => {
  return (
    <>
      <h2 className="text-gray italic text-xl font-semibold text-left">
        PAYPAL
      </h2>
      <p className="text-left text-sm mt-4">
        Clientes que possuem o cartão de crédito cadastrado na Paypal têm a
        facilidade de comprar na Decathlon. Basta selecionar na tela de
        pagamento a opção de pagamento com Paypal que a cobrança será automática
        e o pedido será realizado. O parcelamento com PayPal tem como valor
        mínimo R$5,00 por parcela. Para qualquer outra dúvida sobre, entre em
        contato com a Paypal.
      </p>
      <p className="text-sm mt-4">
        <strong>Em loja física você pode comprar também com:</strong>{' '}
      </p>
      <h2 className="text-gray italic text-xl font-semibold text-left">
        CARTÃO DE DÉBITO
      </h2>
      <p className="text-left text-sm mt-4">
        É aceito o pagamento por aproximação.
      </p>
      <h2 className="text-gray italic text-xl font-semibold text-left">
        DINHEIRO
      </h2>
      <h2 className="text-gray italic text-xl font-semibold text-left">
        CARTEIRAS DIGITAIS
      </h2>
      <p className="text-left text-sm mt-4">
        Mercado Pago, PicPay, Santander Way e Caixa Tem, Agibank, bB, Bradesco,
        Digio, Bitfy, Ame, Payly, Eucard, Original, Next, BanQi, Cielo pay,
        Moeda, Alymento, Uzzo e Banestes.
      </p>
      <br />
      <p>
        O pagamento de compras de produtos adquiridos nos marketplaces com os
        quais a Decathlon firmou contratos pode variar de acordo com o site ou
        plataforma em que se faz a aquisição.
      </p>
    </>
  )
}

export default PaymentOptions
