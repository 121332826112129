import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import PaymentOptions from './PaymentOptions'
import PaymentConditions from './PaymentConditions'
import InstallmentsRules from './InstallmentsRules'

import '../styles.scss'

const FormaPagamento = () => {
  return (
    <>
      <section className="section-services form-payment_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar FormaPagamento-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- FormaPagamento Products-> */}
              <div className="services-content">
                <div className="section__top align-center">
                  <h1 className="text-blue italic text-2xl font-bold text-center">
                    FORMAS DE PAGAMENTO
                  </h1>
                  <h2 className="text-gray selection:italic italic text-xl font-bold text-left">
                    CARTÕES DE CRÉDITO
                  </h2>
                  <p className="text-left mb-2">
                    O e-commerce e lojas Decathlon oferecem como formas de
                    pagamento os cartões de crédito com bandeiras:
                  </p>
                  <div className="flex gap-1">
                    <span className="card-visa" />
                    <span className="card-mastercard" />
                    <span className="card-elo" />
                    <span className="card-diners" />
                    <span className="card-amex" />
                  </div>
                </div>
                <div className="hro">
                  <PaymentConditions />
                  <InstallmentsRules />
                  <h2 className="text-gray italic text-xl font-semibold text-left">
                    Pix
                  </h2>
                  <p className="text-left text-sm">
                    O Pix é um método rápido, seguro e prático para realizar
                    pagamentos eletrônicos, permitindo uma experiência de compra
                    ainda mais simples e ágil. Ao finalizar sua compra em nosso
                    site ou aplicativo, selecione a opção de pagamento via Pix.
                    Você receberá as instruções para realizar o pagamento no
                    aplicativo de seu banco ou instituição financeira. Assim que
                    recebermos a confirmação do pagamento, iniciaremos o
                    processamento de seu pedido para envio. Lembrando que o
                    pagamento via Pix está disponível somente para produtos
                    vendidos e entregues pela Decathlon.
                  </p>
                  <h2 className="text-gray italic text-xl font-semibold text-left">
                    BOLETO
                  </h2>
                  <p className="text-left text-sm">
                    No boleto, você efetua o pagamento à vista. Imprima o
                    documento e efetue o pagamento em qualquer agência bancária,
                    casa lotérica ou pelo internet banking. Nós seremos
                    notificados em até 72 horas, liberando então o seu pedido. O
                    prazo de entrega passa a valer após recebermos a notificação
                    de confirmação de pagamento.
                  </p>

                  <PaymentOptions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FormaPagamento
